import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useProductContext } from "./context/ProductContext";
import PageNavigation from "./components/PageNavigation";
import MyImage from "./components/MyImage";
import { Container } from "./styles/Container";
import FormatPrice from "./Helpers/FormatPrice";
import { TbTruckDelivery, TbReplace } from "react-icons/tb";
import AddToCart from "./components/AddToCart";
import styled, { keyframes } from "styled-components";
import FeatureProduct from "./components/FeatureProduct";
import OutOfStockMessage from "./components/OutOfStockMessage";

// API URL
const API =
  "https://productapi-hamzaretroconsoles-express-iwuv.onrender.com/api/products";

// Define the keyframe animation for loading
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const SingleProduct = () => {
  const { state, getSingleProduct } = useProductContext();
  const singleProduct = state.singleProduct;
  const isSingleLoading = state.isSingleLoading;
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    iD: alias,
    title,
    description,
    color,
    images,
    quantity,
    price,
  } = singleProduct;

  const imageRef = useRef(null);

  const [scrollDone, setScrollDone] = useState(false);

  // Load the product on page load
  useEffect(() => {
    getSingleProduct(`${API}/${id}`);
  }, [id]);

  // Trigger scroll to the top image after page load
  useEffect(() => {
    const timer = setTimeout(() => {
      if (imageRef.current && !scrollDone) {
        window.scrollTo({
          top: imageRef.current.offsetTop,
          behavior: "smooth",
        });
        setScrollDone(true); // Ensure we scroll only once
      }
    }, 500); // Adjust this timeout as needed

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, [singleProduct, scrollDone]);

  // Function to handle product click from FeatureProduct
  const handleFeatureProductClick = (productId) => {
    setScrollDone(false); // Reset scroll flag
    navigate(`/product/${productId}`); // Navigate to the product page
  };

  if (isSingleLoading) {
    return (
      <LoadingWrapper>
        <div className="page-loading">Loading...</div>
      </LoadingWrapper>
    );
  }

  return (
    <Wrapper>
      <PageNavigation title={title} />
      <Container className="container">
        <div className="grid grid-two-column">
          <div className="product_images" ref={imageRef}>
            <MyImage imgs={images} />
          </div>

          <div className="product-data">
            <h2>{title}</h2>
            <p className="product-data-price">
              <del>
                MRP : <FormatPrice price={price + 2000} />
              </del>
            </p>
            <p className="product-data-price product-data-real-price">
              Deal of the Day : <FormatPrice price={price} />
            </p>
            <div
              style={{ whiteSpace: "pre-line", lineHeight: "1.6" }}
              dangerouslySetInnerHTML={{ __html: description }}
            ></div>

            <div className="product-data-warranty">
              <div className="product-warranty-data">
                <TbTruckDelivery className="warranty-icon" />
                <p>Free Delivery</p>
              </div>

              <div className="product-warranty-data">
                <TbReplace className="warranty-icon" />
                <p>14 Days returns T&C Apply </p>
              </div>

              <div className="product-warranty-data">
                <TbTruckDelivery className="warranty-icon" />
                <p>Cash on Delivery </p>
              </div>
            </div>

            <div className="product-data-info">
              <p>
                Available:
                <span> {quantity > 0 ? "In Stock" : "Not Available"}</span>
              </p>
              <p>
                ID : <span> {id} </span>
              </p>
              <p>
                Brand :<span> Nintendo </span>
              </p>
            </div>
            <hr />
            <div className="add-to-cart-container">
              {quantity > 0 ? (
                <AddToCart product={singleProduct} />
              ) : (
                <OutOfStockMessage productName={singleProduct} /> // Render this if the product is out of stock
              )}
            </div>
          </div>
        </div>
      </Container>
      <FeatureProduct onProductClick={handleFeatureProductClick} />
    </Wrapper>
  );
};

// Wrapper styling
// Wrapper styling
const Wrapper = styled.section`
  .container {
    padding: 9rem 0;
    font-family: "Roboto", sans-serif;
  }

  .product_images {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    transition: transform 0.3s ease-in-out;
  }

  .product_images:hover {
    transform: scale(1.05);
  }

  .product-data {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 2rem;
    background-color: #fff;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
  }

  .product-data:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  }

  .product-data-warranty {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    margin-bottom: 1.5rem;
    padding-bottom: 1rem;
  }

  .product-warranty-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .warranty-icon {
      background-color: transparent; /* Remove solid background */
      border-radius: 50%;
      padding: 0.8rem; /* Adjust padding for appropriate size */
      font-size: 3.5rem; /* Increase icon size if needed */
      margin-bottom: 0.5rem;
      transition: all 0.3s ease;
    }

    .warranty-icon:hover {
      background-color: #3a98fe; /* Retain hover effect */
      color: white;
    }

    p {
      font-size: 1.4rem;
      color: #555;
    }
  }

  .product-data-price {
    font-weight: bold;
    font-size: 2rem;
    color: #333;
  }

  .product-data-real-price {
    color: #ff6347;
    font-size: 2.2rem;
  }

  .product-data-info {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    font-size: 1.5rem;
    color: #555;
  }

  .product-data-info span {
    font-weight: bold;
    color: #333;
  }

  hr {
    width: 90%;
    margin: 2rem 0;
    border: 0.1rem solid #ccc;
  }

  /* Center the AddToCart button */
  .add-to-cart-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .product-images {
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
  }

  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    padding: 0 2.4rem;

    .product-data-info {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      font-size: 1.5rem;
      color: #555;
    }

    hr {
      width: 100%; /* Ensure the separator spans the full width on mobile */
    }
  }
`;

// Loading state wrapper styling
const LoadingWrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .page-loading {
    font-size: 2rem;
    color: ${({ theme }) => theme.colors.text};
    animation: ${rotate} 1s linear infinite;
    font-weight: bold;
    color: #ff6347;
  }
`;

export default SingleProduct;

import Product from "./Product";
import styled from "styled-components";
import { useProductContext } from "../context/ProductContext";

const FeatureProduct = () => {
  const featureProducts = useProductContext();

  if (featureProducts.state.isLoading) {
    return (
      <Wrapper className="section">
        <div className="container">
          <div className="featured-heading">Featured Products</div>
          <div className="loading-text">Loading Featured Products ...</div>
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper className="section">
      <div className="container">
        <div className="featured-heading">Our Featured Products</div>
        <div className="grid-feature-products">
          {featureProducts.state.featureProducts.map((curElem) => {
            return <Product key={curElem.id} {...curElem} />;
          })}
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  padding: 3rem 0;
  border-radius: 16px;
  background: linear-gradient(
    135deg,
    ${({ theme }) => theme.colors.primary} 0%,
    ${({ theme }) => theme.colors.secondary} 100%
  );
  box-shadow: inset 0 0 200px rgba(0, 0, 0, 0.1);
  background-attachment: fixed;

  .container {
    max-width: 120rem;
    margin: 0 auto;
    padding: 0 2rem;
    text-align: center;
  }

  .featured-heading {
    font-size: 4.8rem;
    font-weight: bold;
    margin-bottom: 3rem;
    text-transform: capitalize;
    color: ${({ theme }) => theme.colors.primary};
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
  }

  .feature-intro {
    font-size: 2rem;
    color: #5138ee;
    margin-bottom: 1rem;
    font-weight: 500;
  }

  .grid-feature-products {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
  }

  /* CARD */
  .card {
    background: #ffffff; /* Solid white background */
    border: 1px solid #e0e0e0; /* Subtle gray border */
    border-radius: 12px; /* Rounded corners for smoothness */
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }

  .card:hover {
    transform: translateY(-5px);
  }

  figure {
    margin: 0;
    position: relative;
    height: 250px; /* Adjusted for a taller image */
    overflow: hidden;
    background: #f8f8f8; /* In case the image fails to load */
    border-radius: 12px 12px 0 0; /* Rounded top corners */
  }

  figure img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the image covers the space without distortion */
    border-radius: 12px 12px 0 0; /* Rounded top corners */
  }

  h3 {
    font-size: 1.8rem;
    color: ${({ theme }) => theme.colors.text};
    margin: 1rem 0;
    padding: 0 1rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-weight: 600;
  }

  .card-data--price {
    color: ${({ theme }) => theme.colors.helper};
    font-size: 1.6rem;
    padding: 0 1rem 1rem;
    font-weight: 500;
  }

  /* Loading Text */
  .loading-text {
    font-size: 2rem;
    color: ${({ theme }) => theme.colors.secondary};
    font-weight: 600;
  }
`;

export default FeatureProduct;

import React from "react";
import HeroSection from "./components/HeroSection";
import styled from "styled-components";

const About = () => {
  return (
    <StyledAbout>
      <HeroSection
        myData={{
          paragraph:
            "Welcome to Hamza's Retro Console, Pakistan's premier destination for retro gaming enthusiasts! Founded with a passion for nostalgia and a love for classic gaming, we are the first dedicated online store in Pakistan specializing in Nintendo DS, DSi, 3DS, DSi XL, and a curated selection of other retro gaming products.\n\nAt Hamza's Retro Console, we understand the thrill of reliving the golden era of gaming and the importance of preserving these timeless classics. Whether you're a seasoned gamer looking to revisit your favorite titles or a newcomer eager to explore the rich history of gaming, we've got you covered.\n\nOur commitment goes beyond just selling products; we strive to provide an authentic and memorable shopping experience. With a carefully curated inventory, knowledgeable staff, and a user-friendly online platform, we make it easy for you to find the perfect retro gaming treasure.\n\nLocated in Lahore, we are proud to serve the gaming community across Pakistan and beyond. Our mission is to make retro gaming accessible, affordable, and enjoyable for everyone.\n\nThank you for choosing Hamza's Retro Console as your trusted source for all things retro gaming. Join us on this nostalgic journey and let's celebrate the classics together!",
        }}
      />
      <AboutContent>
        <h2>Our Story</h2>
        <p>
          At Hamza's Retro Console, we are driven by a love for the past, and a
          mission to keep retro gaming alive in the hearts of gamers everywhere.
          From vintage Nintendo DS consoles to rare collectibles, we bring you
          the ultimate collection of gaming classics.
        </p>
        <p>
          Whether you’re rediscovering your childhood favorites or discovering
          hidden gems, our store provides everything you need to relive the
          magic of retro gaming. Join us and immerse yourself in a world of
          nostalgia and fun.
        </p>
      </AboutContent>
      <WhyChooseUs>
        <h2>Why Choose Us?</h2>
        <ul>
          <li>Exclusive collection of Nintendo DS, DSi, 3DS, and more</li>
          <li>
            Curated and well-maintained stock for the ultimate gaming experience
          </li>
          <li>Expert staff to guide you in choosing the perfect console</li>
          <li>Fast, reliable shipping across Pakistan and beyond</li>
          <li>Passionate about preserving gaming history and culture</li>
        </ul>
      </WhyChooseUs>
    </StyledAbout>
  );
};

const StyledAbout = styled.div`
  background-color: #f4f4f9;
  color: #333;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin-top: 5rem; /* Added margin-top to create space between the nav and about section */

  @media (max-width: 768px) {
    padding: 2rem 0.5rem;
    margin-top: 4rem; /* Adjusting for smaller screens */
  }
`;

const AboutContent = styled.section`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1rem;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  font-size: 1.1rem;
  line-height: 1.6;

  h2 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    text-align: center;
    color: #ff6f61;
  }

  p {
    margin-bottom: 1rem;
    color: #555;
  }

  @media (max-width: 768px) {
    padding: 1.5rem;
  }
`;

const WhyChooseUs = styled.section`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1rem;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  font-size: 1rem;
  line-height: 1.8;

  h2 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    text-align: center;
    color: #ff6f61;
  }

  ul {
    list-style-type: none;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1.5rem;
    margin: 0;
  }

  li {
    font-size: 1.1rem;
    color: #555;
    display: flex;
    align-items: center;
    gap: 0.8rem;

    &:before {
      content: "✔";
      color: #ff6f61;
      font-size: 1.3rem;
    }
  }

  @media (max-width: 768px) {
    padding: 1.5rem;
  }
`;

export default About;

import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import Nav from "./Nav";

const Header = () => {
  return (
    <MainHeader>
      <NavLink to="/" className="logo-container">
        <LogoContainer>
          <Logo src="/images/logo.png" alt="website logo" />
          <SiteName>Hamza's Retro Consoles</SiteName>
        </LogoContainer>
      </NavLink>
      <Nav />
    </MainHeader>
  );
};

const MainHeader = styled.header`
  height: 8rem; /* Adjusted height for better proportion */
  background-color: ${({ theme }) => theme.colors.bg};
  display: flex;
  justify-content: space-between;
  align-items: center; /* Vertical alignment of children */
  padding: 0 3rem; /* Adjusted padding for better spacing */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center; /* Ensure logo and text are centered vertically */
  justify-content: center;
  height: 100%; /* Ensures the container takes full height of the header */
`;

const Logo = styled.img`
  height: 6rem;
  width: auto; /* Maintains aspect ratio */
`;

const SiteName = styled.span`
  font-size: 3rem; /* Adjusted size for visibility */
  font-weight: bold;
  color: ${({ theme }) => theme.colors.text};
  margin-left: 1.5rem; /* Spacing between logo and text */
  font-family: "Orbitron", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.1em; /* Slight letter spacing for elegance */
  background: linear-gradient(
    90deg,
    #ff8c00,
    #ffa500,
    #ff6347
  ); /* Gradient text */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; /* Only shows the gradient */
  text-shadow: 0 4px 6px rgba(0, 0, 0, 0.3); /* Shadow for depth */

  /* Vertical alignment adjustments */
  display: flex;
  align-items: center; /* Center text vertically */
  line-height: 1; /* Fixes any text height issues */

  &:hover {
    text-shadow: 0 6px 10px rgba(0, 0, 0, 0.5); /* More depth on hover */
    transform: scale(1.05); /* Slight zoom effect */
    transition: all 0.3s ease-in-out; /* Smooth transition */
  }

  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    font-size: 2rem; /* Smaller size for mobile */
  }
`;

export default Header;

import React from "react";
import { NavLink } from "react-router-dom";
import FormatPrice from "../Helpers/FormatPrice";
import styled from "styled-components";

// Styled components for the price wrapper
const PriceWrapper = styled.div`
  font-weight: 700; /* Make the price bolder */
  font-size: 1.5rem; /* Optional: Increase font size */
  color: rgb(234, 120, 6); /* Optional: Change price color */
`;

const Product = ({
  _id,
  title,
  description,
  color,
  images,
  quantity,
  price,
}) => {
  return (
    <NavLink to={`/singleproduct/${_id}`}>
      <Card>
        <figure>
          <img src={images[0]} alt={title} />
          <figcaption className="caption">
            <PriceWrapper>
              <FormatPrice price={price} />
            </PriceWrapper>
          </figcaption>
        </figure>
        <CardData>
          <div className="card-data-flex">
            <h3>{title}</h3>
          </div>
        </CardData>
      </Card>
    </NavLink>
  );
};

// Styled components for the card
const Card = styled.div`
  /* Add your card styling here */
`;

const CardData = styled.div`
  /* Add your card data styling here */
`;

export default Product;

import React from "react";
import styled from "styled-components";
import { BsFillGridFill, BsList } from "react-icons/bs";
import { useFilterContext } from "../context/FilterContext";

const Sort = () => {
  const { filter_products, grid_view, setGridView, setListView, sorting } =
    useFilterContext();

  return (
    <Wrapper className="sort-section">
      {/* 1st column (Grid/List View Switcher) */}
      <div className="sorting-list--grid">
        <button
          className={grid_view ? "active sort-btn" : "sort-btn"}
          onClick={setGridView}
        >
          <BsFillGridFill className="icon" />
        </button>

        <button
          className={!grid_view ? "active sort-btn" : "sort-btn"}
          onClick={setListView}
        >
          <BsList className="icon" />
        </button>
      </div>

      {/* 2nd column (Product Count) */}
      {/* <div className="product-data">
        <p>{`${filter_products.length} Products Available`}</p>
      </div> */}

      {/* 3rd column (Sort Selection Dropdown) */}
      <div className="sort-selection">
        <form action="#">
          <label htmlFor="sort" className="sort-label">
            Sort By:
          </label>
          <select
            name="sort"
            id="sort"
            className="sort-selection--style"
            onClick={sorting}
          >
            <option value="lowest">Low to High</option>
            <option value="highest">High to Low</option>
            <option value="a-z">A-Z</option>
            <option value="z-a">Z-A</option>
          </select>
        </form>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  padding: 1rem 2rem;
  background-color: ${({ theme }) => theme.colors.lightGray};
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  .sorting-list--grid {
    display: flex;
    gap: 1.5rem;
    align-items: center;

    .sort-btn {
      padding: 0.8rem;
      border: 2px solid transparent;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      background-color: ${({ theme }) => theme.colors.darkGray};
      transition: all 0.3s ease;
    }

    .icon {
      font-size: 1.8rem;
    }

    .active {
      background-color: ${({ theme }) => theme.colors.primary};
      color: #fff;
      border-color: ${({ theme }) => theme.colors.primary};
    }

    .sort-btn:hover {
      background-color: ${({ theme }) => theme.colors.lightBlue};
    }
  }

  .product-data {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    color: ${({ theme }) => theme.colors.darkGray};

    p {
      margin: 0;
    }
  }

  .sort-selection {
    display: flex;
    align-items: center;

    .sort-label {
      font-size: 1.2rem;
      margin-right: 1rem;
      color: ${({ theme }) => theme.colors.darkGray};
    }

    .sort-selection--style {
      padding: 0.5rem;
      font-size: 1.2rem;
      cursor: pointer;
      border: 2px solid ${({ theme }) => theme.colors.lightGray};
      border-radius: 6px;
      background-color: ${({ theme }) => theme.colors.white};
      transition: all 0.3s ease;
    }

    .sort-selection--style:hover {
      border-color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export default Sort;

import React from "react";
import { FaMinus, FaPlus } from "react-icons/fa";
import styled from "styled-components";

const CartAmountToggle = ({ quantity, setIncrease, setDecrease }) => {
  return (
    <Wrapper>
      <div className="amount-toggle">
        <button onClick={setDecrease} className="decrease-btn">
          <FaMinus />
        </button>
        <div className="amount-style">{quantity}</div>
        <button onClick={setIncrease} className="increase-btn">
          <FaPlus />
        </button>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .amount-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    font-size: 1.4rem;
  }

  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0.5rem;
    font-size: 2rem;
  }

  .decrease-btn {
    color: red; /* Red color for the minus button */
  }

  .increase-btn {
    color: green; /* Green color for the plus button */
  }

  .amount-style {
    font-size: 2.4rem;
    color: #333;
  }
`;

export default CartAmountToggle;

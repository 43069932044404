import React, { useState } from "react";
import styled from "styled-components";

// API URL
const API =
  "https://productapi-hamzaretroconsoles-express-iwuv.onrender.com/api/products/notify-me";

const OutOfStockMessage = ({ productName }) => {
  const { _id, title, color, quantity: availableQuantity } = productName;
  console.log(title);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (phoneNumber) {
      try {
        setSubmitted(true);
        // Send POST request to the API
        const response = await fetch(API, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            productTitle: title, // Pass the product name instead of productId
            customerPhoneNumber: phoneNumber,
          }),
        });

        const data = await response.json();

        if (response.ok) {
          console.log("Phone number submitted:", phoneNumber);
        } else {
          setError(data.message || "Something went wrong, please try again.");
        }
      } catch (err) {
        console.error(err);
        setError("An error occurred while submitting. Please try again.");
      }
    }
  };

  return (
    <MessageWrapper>
      <h3>Product is out of stock</h3>
      <p>
        If you'd like to be notified when this product is available again,
        please share your mobile number:
      </p>
      {!submitted ? (
        <form onSubmit={handleSubmit}>
          <Input
            type="text"
            placeholder="Enter your mobile number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            maxLength="15"
          />
          <Button type="submit">Submit</Button>
        </form>
      ) : (
        <p>Thank you! We will notify you when the product is available.</p>
      )}
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </MessageWrapper>
  );
};

const MessageWrapper = styled.div`
  text-align: center;
  padding: 2rem;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
`;

const Input = styled.input`
  padding: 0.8rem;
  width: 60%;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  text-align: center;
`;

const Button = styled.button`
  padding: 0.8rem 2rem;
  background-color: #ff6347;
  color: #fff;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #e5533d;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 0.9rem;
  margin-top: 1rem;
`;

export default OutOfStockMessage;

import React from "react";
import styled from "styled-components";
import Product from "./Product";

const GridView = ({ products }) => {
  return (
    <Wrapper className="section">
      <div className="container grid grid-three-column">
        {products.map((curElem) => {
          return (
            <div key={curElem._id} className="product-card">
              {/* Out of Stock label */}
              {curElem.quantity === 0 && (
                <div className="out-of-stock-label">Out of Stock</div>
              )}
              <Product {...curElem} />
            </div>
          );
        })}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  padding: 7rem 0;
  background-color: ${({ theme }) => theme.colors.bg};

  .container {
    max-width: 120rem;
  }

  .grid {
    gap: 3.5rem;
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(280px, 1fr)
    ); /* Flexible grid layout */
  }

  .product-card {
    position: relative;
    border-radius: 1.2rem;
    background: ${({ theme }) => theme.colors.cardBg};
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: all 0.3s ease-in-out;

    &:hover {
      transform: translateY(-10px);
      box-shadow: 0 12px 20px rgba(0, 0, 0, 0.2);
    }
  }

  .out-of-stock-label {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: rgba(255, 0, 0, 0.9);
    color: #fff;
    padding: 5px 15px;
    font-size: 1.4rem;
    font-weight: bold;
    border-radius: 5px;
    z-index: 10; /* Ensure it stays on top */
    transition: all 0.2s ease;
  }

  .out-of-stock-label:hover {
    background-color: rgba(255, 0, 0, 1);
    cursor: pointer;
  }

  figure {
    position: relative;
    width: 100%;
    overflow: hidden;
    border-radius: 1rem 1rem 0 0;
    height: 250px; /* Fixed height for better control of image size */
    transition: all 0.3s ease;
  }

  figure img {
    width: 100%;
    height: 100%;
    object-fit: contain; /* Ensure image fits entirely within the container */
    border-radius: 1rem 1rem 0 0;
    transition: all 0.3s ease;
  }

  .card-data {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: ${({ theme }) => theme.colors.cardContentBg};
    border-radius: 0 0 1rem 1rem;
    transition: all 0.3s ease;
  }

  h3 {
    font-size: 2rem;
    color: ${({ theme }) => theme.colors.text};
    font-weight: 500;
    margin-bottom: 1rem;
    text-transform: capitalize;
    transition: color 0.3s ease;

    &:hover {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  .card-data--price {
    font-size: 1.6rem;
    color: ${({ theme }) => theme.colors.helper};
  }

  .btn {
    margin-top: 1.5rem;
    padding: 0.8rem 2rem;
    background-color: ${({ theme }) => theme.colors.primary};
    border-radius: 5px;
    color: #fff;
    text-align: center;
    font-size: 1.6rem;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      background-color: ${({ theme }) => theme.colors.secondary};
    }

    a {
      color: #fff;
      font-size: 1.6rem;
      text-decoration: none;
    }
  }

  /* Mobile responsiveness */
  @media (max-width: 768px) {
    .grid {
      grid-template-columns: repeat(
        auto-fill,
        minmax(200px, 1fr)
      ); /* Adjust column for mobile */
    }

    .product-card {
      margin: 0 1rem; /* Add some margin for smaller screens */
    }

    figure {
      height: 200px; /* Smaller height for mobile */
    }

    figure img {
      object-fit: contain; /* Ensure the image fits inside */
    }

    .card-data {
      padding: 1rem;
    }

    h3 {
      font-size: 1.8rem; /* Slightly smaller title on mobile */
    }

    .card-data--price {
      font-size: 1.4rem; /* Smaller price font on mobile */
    }

    .btn {
      font-size: 1.4rem; /* Adjust button size for mobile */
    }
  }

  @media (max-width: 480px) {
    .grid {
      grid-template-columns: 1fr; /* Stack items in one column for very small screens */
    }

    figure {
      height: 150px; /* Further reduce the image height on small screens */
    }

    figure img {
      object-fit: contain; /* Ensure the image still fits properly */
    }
  }
`;

export default GridView;
